/**
 * formToJSON
 *
 * credits: https://www.learnwithjason.dev/blog/get-form-values-as-json/
 *
 * @param {HTMLFormElement} form
 * @param {*} emptyForm
 */
 function formToJSON(form, emptyForm=false){
    const isValidElement = function(element){
        return element.name && element.value;
    };

    const isValidValue = function(element){
        return (!['checkbox', 'radio'].includes(element.type) || element.checked);
    };

    const isCheckbox = element => element.type === 'checkbox';
    const isMultiSelect = element => element.options && element.multiple;

    const getSelectValues = options => [].reduce.call(options, (values, option) => {
        return option.selected ? values.concat(option.value) : values;
    }, []);

    let jsonified = [].reduce.call(form.elements, function(data, element){
        if(isValidElement(element) && isValidValue(element)){
            if(isCheckbox(element)) data[element.name] = (data[element.name] || []).concat(element.value);
            else if(isMultiSelect(element)) data[element.name] = getSelectValues(element);
            else data[element.name] = element.value;

            if(emptyForm) form[element.name].value = "";
        }

        return data;
    }, {});

    return jsonified;
}

export { formToJSON };