/**
 * @module Media
 * See: https://css-tricks.com/a-complete-guide-to-css-media-queries/
 */
export class Media
{
    /**
     * The prefers-reduced-motion CSS media feature is used to
     * detect if the user has requested that the system minimize
     * the amount of non-essential motion it uses.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
     */
    static get FEATURE_PREFERS_REDUCED_MOTION_REDUCE()
    {
        return "(prefers-reduced-motion: reduce)";
    }

    static get FEATURE_ORIENTATION_LANDSCAPE()
    {
        return "(orientation: landscape)";
    }

    static get FEATURE_ORIENTATION_PORTRAIT()
    {
        return "(orientation: portrait)";
    }

    static get FEATURE_PREFERS_COLOR_SCHEME_DARK()
    {
        return "(prefers-color-scheme: dark)";
    }

    static get FEATURE_PREFERS_COLOR_SCHEME_LIGHT()
    {
        return "(prefers-color-scheme: light)";
    }

    static get FEATURE_ANY_HOVER_HOVER()
    {
        return "(any-hover: hover)";
    }

    static get FEATURE_ANY_HOVER_NONE()
    {
        return "(any-hover: none)";
    }

    static get prefersReducedMotion()
    {
        const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
        return mediaQuery.matches;
    }

    /**
     * @param {String} v
     */
    static is(v)
    {
        const mediaQuery = window.matchMedia(`${v}`);
        return mediaQuery.matches;
    }

    /**
     * @param {Number} width
     */
    static isMinWidth(width)
    {
        const mediaQuery = window.matchMedia(`(min-width: ${width}px)`);
        return mediaQuery.matches;
    }

    /**
     * @param {Number} width
     */
    static isMaxWidth(width)
    {
        const mediaQuery = window.matchMedia(`(max-width: ${width}px)`);
        return mediaQuery.matches;
    }

    /**
     * https://css-tricks.com/working-with-javascript-media-queries/
     *
     */
    static get isMobile()
    {
        const mediaQuery = window.matchMedia('(max-width: 800px)');
        return mediaQuery.matches;
    }

    static get isTablet()
    {
        const mediaQuery = window.matchMedia('(min-width: 800px)');
        return mediaQuery.matches;
    }

    static get isDesktop()
    {
        const mediaQuery = window.matchMedia('(min-width: 960px)');
        return mediaQuery.matches;
    }

    static get isExtraWide()
    {
        const mediaQuery = window.matchMedia('(min-width: 1200px)');
        return mediaQuery.matches;
    }

    static get width()
    {
        return this.cw = parseInt((screen.availWidth || window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth), 10);
    }

    static get height()
    {
        return this.cw = this.ch = parseInt((screen.availHeight || window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight), 10);
    }
}
