const routes = {
    "home.index": {
        "uri": "\/"
    },
    "contactus.index": {
        "uri": "contact"
    },
    "contactus.store": {
        "uri": "contact"
    },
    "blog.index": {
        "uri": "blog"
    },
    "blog.load": {
        "uri": "load-blogs"
    },
    "publicposts.show": {
        "uri": "p\/{slug}"
    },
    "home.posts.load": {
        "uri": "load-posts\/{categoryId}"
    },
    "home.posts.latest": {
        "uri": "load-latest"
    },
    "home.about": {
        "uri": "about"
    },
    "subscribers.store": {
        "uri": "subscribe"
    },
    "subscribers.subscribe": {
        "uri": "resubscribe\/{id}"
    },
    "subscribers.unsubscribe": {
        "uri": "unsubscribe\/{id}"
    },
    "postcomments.store": {
        "uri": "posts\/{id}"
    },
    "postcomments.activate": {
        "uri": "post-comment\/{id}\/activate"
    },
    "categoryposts.show": {
        "uri": "c\/{slug}"
    },
    "categoryposts.load.latest": {
        "uri": "c\/{id}\/load-latest"
    },
    "privacy.index": {
        "uri": "privacy"
    },
    "wysiwyg.upload": {
        "uri": "wysiwyg-uploads"
    },
    "register.create": {
        "uri": "register"
    },
    "register.store": {
        "uri": "register"
    },
    "login.create": {
        "uri": "login"
    },
    "login.store": {
        "uri": "login"
    },
    "logout": {
        "uri": "logout"
    },
    "verify.email": {
        "uri": "verify\/email\/{token}"
    },
    "passwords.forgot": {
        "uri": "passwords\/forgot"
    },
    "passwords.link": {
        "uri": "passwords\/forgot"
    },
    "passwords.reset": {
        "uri": "passwords\/reset\/{token}"
    },
    "passwords.store": {
        "uri": "passwords\/reset\/{token}"
    },
    "profile.index": {
        "uri": "dashboard\/profile"
    },
    "profile.update": {
        "uri": "dashboard\/profile\/{id}"
    },
    "cms.posts.create": {
        "uri": "dashboard\/author"
    },
    "cms.posts.index": {
        "uri": "dashboard\/author\/posts"
    },
    "cms.posts.show": {
        "uri": "dashboard\/author\/posts\/{id}"
    },
    "cms.posts.store": {
        "uri": "dashboard\/author\/posts"
    },
    "cms.posts.edit": {
        "uri": "dashboard\/author\/posts\/{id}\/edit"
    },
    "cms.posts.update": {
        "uri": "dashboard\/author\/posts\/{id}"
    },
    "cms.posts.destroy": {
        "uri": "dashboard\/author\/posts\/{id}"
    },
    "cms.categories.index": {
        "uri": "dashboard\/admin\/categories"
    },
    "cms.categories.show": {
        "uri": "dashboard\/admin\/categories\/{id}"
    },
    "cms.categories.store": {
        "uri": "dashboard\/admin\/categories"
    },
    "cms.categories.update": {
        "uri": "dashboard\/admin\/categories\/{id}"
    },
    "cms.categories.destroy": {
        "uri": "dashboard\/admin\/categories\/{id}"
    },
    "users.index": {
        "uri": "dashboard\/admin\/users"
    },
    "users.update": {
        "uri": "dashboard\/admin\/users\/{id}"
    },
    "usergroups.index": {
        "uri": "dashboard\/admin\/user-groups"
    },
    "usergroups.store": {
        "uri": "dashboard\/admin\/user-groups"
    },
    "usergroups.roles": {
        "uri": "dashboard\/admin\/user-groups\/{id}\/roles"
    },
    "usergroups.assign.role": {
        "uri": "dashboard\/admin\/user-groups\/assign-role"
    },
    "usergroups.revoke.role": {
        "uri": "dashboard\/admin\/user-groups\/revoke-role"
    },
    "roles.index": {
        "uri": "dashboard\/admin\/roles"
    },
    "roles.permissions": {
        "uri": "dashboard\/admin\/roles\/{id}\/permissions"
    },
    "roles.assign.permission": {
        "uri": "dashboard\/admin\/roles\/assign-permission"
    },
    "roles.revoke.permission": {
        "uri": "dashboard\/admin\/roles\/revoke-permission"
    },
    "permissions.index": {
        "uri": "dashboard\/admin\/permissions"
    },
    "services.index": {
        "uri": "dashboard\/admin\/services"
    },
    "subjects.index": {
        "uri": "dashboard\/admin\/subjects"
    },
    "admin.orders.index": {
        "uri": "dashboard\/admin\/orders"
    },
    "admin.orders.show": {
        "uri": "dashboard\/admin\/orders\/{id}\/show"
    },
    "admin.subscribers.index": {
        "uri": "dashboard\/admin\/subscribers"
    }
};

const route = (routeName, params = [], absolute = true) => {
    const _route = routes[routeName];
    if (_route == null) throw "Requested route doesn't exist";

    let uri = _route.uri;

    const matches = uri.match(/{[\w]+\??}/g) || [];
    const optionals = uri.match(/{[\w]+\?}/g) || [];

    const requiredParametersCount = matches.length - optionals.length;

    if (params instanceof Array) {
      if (params.length < requiredParametersCount) throw "Missing parameters";

      for (let i = 0; i < requiredParametersCount; i++)
        uri = uri.replace(/{[\w]+\??}/, params.shift());

      for (let i = 0; i < params.length; i++)
        uri += (i ? "&" : "?") + params[i] + "=" + params[i];
    } else if (params instanceof Object) {
      let extraParams = matches.reduce((ac, match) => {
        let key = match.substring(1, match.length - 1);
        let isOptional = key.endsWith("?");
        if (params.hasOwnProperty(key.replace("?", ""))) {
          uri = uri.replace(new RegExp(match.replace("?", "\\?"), "g"), params[key.replace("?", "")]);
          delete ac[key.replace("?", "")];
        } else if (isOptional) {
            uri = uri.replace("/" + new RegExp(match, "g"), "");
        }
        return ac;
      }, params);

      Object.keys(extraParams).forEach((key, i) => {
        uri += (i ? "&" : "?") + key + "=" + extraParams[key];
      });
    }

    if (optionals.length > 0) {
      for (let i in optionals) {
        uri = uri.replace("/" + optionals[i], "");
      }
    }

    if (uri.includes("}")) throw "Missing parameters";

	if (absolute)
		return `https://estherkamande.com/${uri}`;

    return `/${uri}`;
};

export { route };