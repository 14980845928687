/**
 * @module Network
 *
 * Fetch API Wrapper
 * for network IO
 *
 */
export class Network
{
    constructor()
    {
        // code
    }

    /**
     *
     * @param {*} url
     * @param {*} $acceptJson
     * @param {AbortSignal} signal
     * @returns
     */
    static async get(url, $acceptJson=false, signal=null, cors=false, headers={})
    {
        let method = 'GET';
        try {
            let params = {headers:{}};
            if(! cors) {
                params.headers["Content-Type"] = "application/json; charset=UTF-8";
                params.headers["X-CSRF-TOKEN"] = Network.csrf("csrf-token");
                params.cache = "no-cache";
                params.mode = "same-origin";
            }
            for (const [headerName, headerValue] of Object.entries(headers)) {
                if(headers.hasOwnProperty(headerName)) {
                    params.headers[headerName] = headerValue;
                }
            }
            params.method = method;
            if(signal) {
                params.signal = signal
            }

            if($acceptJson && !cors)
            {
                params.headers["Accept"] = "application/json";
            }

            let response = await fetch(url, params);
            if(!response.ok || (response.status != '200' && response.status != '304')){
                throw new Error(response.status + ": " + response.statusText);
            }

            let data;
            const contentType = response.headers.get('content-type');

            if(contentType && contentType.includes('text/plain')) {
                data = await response.text();
                return Promise.resolve(data);
            }

            data = await response.json();
            if(data.error) throw new Error(data.message);
            return Promise.resolve(data);
        } catch(error){
            return Promise.reject(error);
        }
    }

    static async post(url, dataObj, hasFileUpload=false)
    {
        return Network.store(url, dataObj, hasFileUpload);
    }

    static async put(url, dataObj, hasFileUpload=false)
    {
        return Network.update(url, dataObj, hasFileUpload);
    }

    static async delete(url)
    {
        return Network.destroy(url);
    }

    static async store(url, dataObj, hasFileUpload=false)
    {
        let method = 'POST';
        try {
            let params = {headers:{}};
            if(!hasFileUpload) params.headers["Content-Type"] = "application/json; charset=UTF-8";
            params.headers["X-CSRF-TOKEN"] = Network.csrf("csrf-token");
            params.cache = "no-cache";
            params.mode = "same-origin";
            params.method = method;
            if(! hasFileUpload ) params.body = JSON.stringify(dataObj);
            if( hasFileUpload ) params.body = dataObj;

            let response = await fetch(url, params);
            if(!response.ok || (response.status != '200' && response.status != '304')){
                throw new Error(response.status + ": " + response.statusText);
            }

            let data     = await response.json();
            if(data.error) throw new Error(data.message);
            return Promise.resolve(data);
        } catch(error){
            return Promise.reject(error);
        }
    }

    static async update(url, dataObj, hasFileUpload=false)
    {
        let method = 'PUT';
        try {
            let params = {headers:{}};
            if(!hasFileUpload) params.headers["Content-Type"] = "application/json; charset=UTF-8";
            params.headers["X-CSRF-TOKEN"] = Network.csrf("csrf-token");
            params.cache = "no-cache";
            params.mode = "same-origin";
            params.method = method;
            if(! hasFileUpload ) params.body = JSON.stringify(dataObj);
            if( hasFileUpload ) params.body = dataObj;

            let response = await fetch(url, params);
            if(!response.ok || (response.status != '200' && response.status != '304')){
                throw new Error(response.status + ": " + response.statusText);
            }

            let data     = await response.json();
            if(data.error) throw new Error(data.message);
            return Promise.resolve(data);
        } catch(error){
            return Promise.reject(error);
        }
    }

    static async destroy(url)
    {
        let method = 'DELETE';
        try {
            let params = {headers:{}};
            params.headers["Content-Type"] = "application/json; charset=UTF-8";
            params.headers["X-CSRF-TOKEN"] = Network.csrf("csrf-token");
            params.cache = "no-cache";
            params.mode = "same-origin";
            params.method = method;

            let response = await fetch(url, params);
            if(!response.ok || (response.status != '200' && response.status != '304')){
                throw new Error(response.status + ": " + response.statusText);
            }

            let data     = await response.json();
            if(data.error) throw new Error(data.message);
            return Promise.resolve(data);
        } catch(error){
            return Promise.reject(error);
        }
    }

    static csrf(name="csrf-token")
    {
        const metas = document.getElementsByTagName('meta');
        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === name) {
                return metas[i].getAttribute('content');
            }
        }
    }

    static get forbiddenHeaders()
    {
        return [
            "Accept-Charset",
            "Accept-Encoding",
            "Access-Control-Request-Headers",
            "Access-Control-Request-Method",
            "Connection",
            "Content-Length",
            "Cookie",
            "Cookie2",
            "Date",
            "DNT",
            "Expect",
            "Host",
            "Keep-Alive",
            "Origin",
            "Referer",
            "TE",
            "Trailer",
            "Transfer-Encoding",
            "Upgrade",
            "Via",
            "Proxy-*",
            "Sec-*"
        ];
    }

    static get CORSSafeRequestHeaders()
    {
        return [
            "Accept",
            "Accept-Language",
            "Content-Language",
            "Content-Type"
        ];
    }

    static get CORSSafeResponseHeaders()
    {
        /*/ https://javascript.info/fetch-crossorigin /*/
        return [
            "Cache-Control",
            "Content-Language",
            "Content-Type",
            "Expires",
            "Last-Modified",
            "Pragma"
        ];
    }
}
