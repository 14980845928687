import { wait } from "./f";
import { Media } from "./Media";
import { formToJSON } from "./form";
import { highZ } from "./zindex";
import { loadInOneStylesheet } from "./css";

/**
 * @module Toast
 *
 * https://stackoverflow.com/questions/8052178/difference-between-document-documentelement-clientheight-and-document-body-clien
 *
 */
export class Toast
{
    /**
     * Displays a message and waits indefinitely
     *
     * @param {String} message the message to display
     */
    static async wait(message="Processing. Please wait...")
    {
        Toast.make(message, Toast.info, Toast.DELAY_INFINITY);
    }

    static async closeAll()
    {
        return new Promise((resolve, reject) => {
            try
            {
                document.querySelectorAll(".toast-message").forEach((i) => {
                    i.click();
                });
                resolve();
            }
            catch(error)
            {
                reject(new Error(error));
            }
        });
    }

    static async form(htmlForm)
    {
        return new Promise(function(resolve, reject){
            let argType = Object.prototype.toString.call(htmlForm);
            if(argType !== '[object HTMLFormElement]')
            {
                reject(new Error(`Expected HTMLFormElement, got ${argType}`));
            }

            htmlForm.classList.add("toast-form");

            Toast._loadStyles();

            const display = document.createElement("div");
            display.classList.add("toast-confirm", "anim", "fadeIn");
            display.insertAdjacentHTML("beforeend", `
            <div class="toast-confirm-message">
            ${htmlForm.outerHTML}
            </div>
            <div class="toast-confirm-actions">
                <a href="javascript:;" class="toast-cancel-button" data-action="cancel">Cancel</a>
                <a href="javascript:;" class="toast-submit-button" data-action="okay">Submit</a>
            </div>`);

            const overlay = document.createElement("div");
            overlay.classList.add("toast-overlay");
            overlay.style.zIndex = highZ() - 1;
            display.style.zIndex = highZ() + 1;

            if(Media.isMobile)
            {
                display.style.maxWidth = "80vw";
                display.style.width = "80vw";
                display.style.left = "10vw";
            }

            document.body.appendChild(display);
            document.body.appendChild(overlay);

            const okay = display.querySelector("a[data-action='okay']");
            const cancel = display.querySelector("a[data-action='cancel']");

            cancel.addEventListener("click", function(e){
                try
                {
                    display.parentNode.removeChild(display);
                    overlay.parentNode.removeChild(overlay);
                    reject();
                } catch(error) {}
            });

            okay.addEventListener("click", function(e){
                try
                {
                    htmlForm = document.querySelector(".toast-confirm-message .toast-form");
                    if(! htmlForm.reportValidity()){ return; }
                    display.parentNode.removeChild(display);
                    overlay.parentNode.removeChild(overlay);
                    resolve(formToJSON(htmlForm));
                } catch(error) {}
            });
        });
    }

    static async confirm(message)
    {
        return new Promise(function(resolve, reject){
            Toast._loadStyles();

            const display = document.createElement("div");
            display.classList.add("toast-confirm", "anim", "fadeIn");
            display.insertAdjacentHTML("beforeend", `
            <div class="exclamation">
                <i class="fas fa-exclamation"></i>
            </div>
            <div class="toast-confirm-message">
            ${message}
            </div>
            <div class="toast-confirm-actions">
                <a href="javascript:;" class="toast-cancel-button" data-action="cancel">Cancel</a>
                <a href="javascript:;" class="toast-submit-button" data-action="okay">OK</a>
            </div>`);

            const overlay = document.createElement("div");
            overlay.classList.add("toast-overlay");
            overlay.style.zIndex = highZ() - 1;
            display.style.zIndex = highZ() + 1;

            if(Media.isMobile)
            {
                display.style.maxWidth = "80vw";
                display.style.width = "80vw";
                display.style.left = "10vw";
            }

            document.body.appendChild(display);
            document.body.appendChild(overlay);

            const okay = display.querySelector("a[data-action='okay']");
            const cancel = display.querySelector("a[data-action='cancel']");

            cancel.addEventListener("click", function(e){
                try
                {
                    display.parentNode.removeChild(display);
                    overlay.parentNode.removeChild(overlay);
                    reject();
                } catch(error) {}
            });

            okay.addEventListener("click", function(e){
                try
                {
                    display.parentNode.removeChild(display);
                    overlay.parentNode.removeChild(overlay);
                    resolve();
                } catch(error) {}
            });
        });
    }

    static async make(message, type='info', delay=1)
    {
        return new Promise(function(resolve, reject){
            Toast._loadStyles();
            try
            {
                type = Toast.types[type];
            } catch(error)
            {
                type = Toast.types.info;
            }

            const display = document.createElement("div");
            display.classList.add("toast-message");
            display.classList.add(`${type.name}`);
            display.insertAdjacentHTML("beforeend", `
            <div class="type-icon">
                <i class="${type.icon}"></i>
            </div>
            <div class="type-message">
            ${message}
            </div>`);
            display.style.zIndex = highZ() + 1;
            display.style.backgroundColor = type.bg_color;
            display.style.color = type.fg_color;

            document.body.appendChild(display);

            if(delay === Toast.DELAY_INFINITY)
            {
                display.addEventListener("click", function(e){
                    try
                    {
                        display.parentNode.removeChild(display);
                        resolve();
                    } catch(error) {}
                });
            }
            else
            {
                const timeoutId = wait(delay, function() {
                    try
                    {
                        display.parentNode.removeChild(display);
                    } catch(error) {}

                    resolve();
                });

                display.addEventListener("click", function(e){
                    try
                    {
                        display.parentNode.removeChild(display);
                        clearTimeout(timeoutId);
                        resolve();
                    } catch(error) {}
                });
            }
        });
    }

    static _loadStyles()
    {
        if(typeof toastDocumentStylesheetMarker !== 'undefined')
        {
            return;
        }

        loadInOneStylesheet([
            [ `.toast-confirm`, `
                position: fixed;
                top: 40%;
                left: calc(50% - 210px);
                margin: auto;
                max-width: 420px;
                background-color: #fff;
                border-radius: 5px;
                padding: 10px 14px;
                box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                `
            ],
            [ `.toast-overlay`, `
                position:fixed;
                width:100%;
                height:100%;
                top:0;
                left:0;
                background-color:rgba(0,0,0,.6);
                z-index: ${highZ() + 1};
                `
            ],
            [ `.toast-confirm .exclamation`, `
                font-size: 3em;
                text-align: center;
                width: 100%;
                padding: 10px;
                color: ${Toast.types.warning.bg_color};
                `
            ],
            [ `.toast-confirm .toast-confirm-actions`, `
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
                margin-top: 10px;
                `
            ],

            [
                `.toast-message`, `
                cursor: pointer;
                position: fixed;
                top: 0px;
                left: calc(50vw - 140px);
                padding: 10px 10px;
                width: 280px;
                border-radius: 5px;
                opacity: 1;
                font-weight: bold;
                box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                word-break: break-all;
                word-break: break-word;
                `
            ],
            [
                `.toast-message .type-icon`, `
                width:10%;
                `
            ],
            [
                `.toast-message .type-message`, `
                width:90%;
                `
            ],
            [ `.toast-message.info`, `background-color: ${Toast.types.info.bg_color}; color: ${Toast.types.info.fg_color};` ],
            [ `.toast-message.success`, `background-color: ${Toast.types.success.bg_color}; color: ${Toast.types.success.fg_color};` ],
            [ `.toast-message.warning`, `background-color: ${Toast.types.warning.bg_color}; color: ${Toast.types.warning.fg_color};` ],
            [ `.toast-message.error`, `background-color: ${Toast.types.error.bg_color}; color: ${Toast.types.error.fg_color};` ],
            [ `.toast-submit-button, .toast-cancel-button`, `
                font-size: 1rem;
                margin: 1em 1.2em;
                padding: .8em 1em;
                border-radius: 5px;
                display: inline-block;
                -webkit-user-select: none;
                user-select: none;
                `
            ],
            [ `.toast-submit-button:hover, .toast-cancel-button:hover`, `opacity:.98; color: #fff` ],
            [ `.toast-submit-button`, `background-color: #59b78c; color: #fff;` ],
            [ `.toast-cancel-button`, `background-color: grey; color: #fff;` ],
            [`.toast-confirm-actions a`, `text-decoration:none;`],
            [`.toast-confirm-actions a:hover`, `text-decoration:none;`]
        ]);

        document.body.insertAdjacentHTML("afterbegin", `<div id="toastDocumentStylesheetMarker" style="display:none !important;"></div>`);
    }

    static get types()
    {
        const types = {};

        types.info = {
            name: 'info',
            icon: 'fas fa-info-circle',
            fg_color: '#fff',
            bg_color: '#2e99bd'
        };

        types.success = {
            name: 'success',
            icon: 'fas fa-check-circle',
            fg_color: '#fff',
            bg_color: '#5bba6f'
        };

        types.warning = {
            name: 'warning',
            icon: 'fas fa-exclamation-triangle',
            fg_color: '#fff',
            bg_color: '#b37701'
        };

        types.error = {
            name: 'error',
            icon: 'fas fa-exclamation-circle',
            fg_color: '#fff',
            bg_color: '#bd263f'
        };

        return types;
    }

    static get info()
    {
        return Toast.types.info.name;
    }

    static get success()
    {
        return  Toast.types.success.name;
    }

    static get warning()
    {
        return Toast.types.warning.name;
    }

    static get error()
    {
        return Toast.types.error.name;
    }

    static get DELAY_INFINITY()
    {
        return 'INFINITY';
    }

    /**
     * Ref: https://gomakethings.com/converting-a-string-into-markup-with-vanilla-js/
     * https://gomakethings.com/free-icons-for-use-in-your-projects/
     *
     * @param {*} str
     * @returns
     */
    static stringToHTML(str)
    {
        let support = (function () {
            if (!window.DOMParser)
            {
                return false;
            }

            try { (new DOMParser()).parseFromString('X', 'text/html'); } catch(error){ return false; }

            return true;
        })();

        if(support)
        {
            let parser = new DOMParser();
            let doc = parser.parseFromString(str, 'text/html');
            return doc.body;
        }

        let dom = document.createElement('div');
        dom.innerHTML = str;
        return dom;
    }
}
