/**
 * datetime
 *
 * @module datetime
 */

/**
 * monthsList
 *
 * @param {boolean} shortenNames
 */
function monthsList(shortenNames=false){
    return !shortenNames
    ? ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"]
    : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
}

/**
 * toOrdinalDate
 *
 * @param {String|Date} date
 */
function toOrdinalDate(date){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]') throw new Error("date must be a valid Date object");

    let parts = toDateParts(date);

    return `<span class="ordinal-date">${ parts.date +"<sup>"+ parts.suffix +"</sup> "+ parts.month + " " + parts.year }</span>`;
}

/**
 *
 * @param {Number|Date} num
 *
 * reference: https://stackoverflow.com/questions/15397372/javascript-new-date-ordinal-st-nd-rd-th
 *
 */
function ordinalSuffix(num){
    if(Object.prototype.toString.call(num) === '[object Date]') num = (new Date(num)).getDate();

    num = parseInt(num, 10);

    let suffixes = ['st', 'nd', 'rd', 'th'];

    if (num > 3 && num < 21) return 'th';

    switch (num % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}

function toDateParts(date){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]') throw new Error("date must be a valid Date object");

    let year    = date.getFullYear(),
        month   = monthsList()[date.getMonth()],
        dt      = date.getDate(),
        suffix  = ordinalSuffix(date);

    return { year: year, month: month, date: dt, suffix: suffix };
}

/**
 * toDateTime
 *
 * Y-m-d H:i:s
 *
 * @param {Date|String} date
 * @param {String} dateSeparator
 * @param {String} timeSeparator
 */
function toDateTime(date, dateSeparator="-", timeSeparator=":"){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]') throw new Error("date must be a valid Date object");
    return date.getFullYear() + dateSeparator + ("0" + (date.getMonth() + 1)).slice(-2) + dateSeparator + ("0" + date.getDate()).slice(-2) + " " +
        ("0" + date.getHours()).slice(-2) + timeSeparator + ("0" + date.getMinutes()).slice(-2) + timeSeparator + ("0" + date.getSeconds()).slice(-2);
}

/**
 * toDate
 *
 * Y-m-d
 *
 * @param {Date|String} date
 * @param {String} separator
 */
function toDate(date, separator="-"){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]') throw new Error("date must be a valid Date object");
    return date.getFullYear() + separator + ("0" + (date.getMonth() + 1)).slice(-2) + separator + ("0" + date.getDate()).slice(-2);
}

/**
 * toTime
 *
 * @param {Date|String} date
 * @param {String} timeSeparator separator
 * @param {Boolean} seconds whether to include seconds
 *
 * @return {String} HH:ii:ss
 */
function toTime(date, timeSeparator=":", seconds=true){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]') throw new Error("date must be a valid Date object");
    let hhii = ("0" + date.getHours()).slice(-2) + timeSeparator + ("0" + date.getMinutes()).slice(-2);
    let ss = timeSeparator + ("0" + date.getSeconds()).slice(-2);
    return seconds?hhii+ss:hhii;
}

/**
 * toTime12
 *
 * @param {Date|String} date
 */
function toTime12(date) {
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]') throw new Error("date must be a valid Date object");
    let hours = date.getHours();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return hours + ':' + ("0" + date.getMinutes()).slice(-2) + ' ' + ampm;
}

/**
 * getWeekDates
 *  Given a valid date object or string, this function
 *  returns a list of Dates for the week from Sunday to Saturday,
 *  indexed from 0 to 6
 *
 * @param {Date|String} date
 *
 * @return {Array}
 *
 */
function getWeekDates(date){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]') throw new Error("date must be a valid Date object");

    let Y = date.getFullYear();
    let m = date.getMonth();
    let d = date.getDate();
    let w = date.getDay();
    let ms = (new Date(Y, m, d)).getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    if(w != 0) ms = ms - (w * dms);

    return [new Date(ms), new Date(ms + dms), new Date(ms + (2 * dms)), new Date(ms + (3 * dms)), new Date(ms + (4 * dms)), new Date(ms + (5 * dms)), new Date(ms + (6 * dms))];
}

/**
 * addMinutes
 *
 * @param {String|Date} date
 * @param {Number} count
 */
function addMinutes(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    if(isNaN(count)) throw new TypeError(`Invalid argument type: expected Number, got ${count}`);
    let ms = date.getTime();
    let dms = 1 * 60 * 1000;
    dms = dms * 1 * count;
    return new Date(ms + dms);
}

/**
 * subtractMinutes
 *
 * @param {String|Date} date
 * @param {Number} count
 */
function subtractMinutes(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    if(isNaN(count)) throw new TypeError(`Invalid argument type: expected Number, got ${count}`);
    let ms = date.getTime();
    let dms = 1 * 60 * 1000;
    dms = dms * 1 * count;
    return new Date(ms - dms);
}

/**
 * addHours
 *
 * @param {String|Date} date
 * @param {Number} count
 */
function addHours(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    if(isNaN(count)) throw new TypeError(`Invalid argument type: expected Number, got ${count}`);
    let ms = date.getTime();
    let dms = 1 * 60 * 60 * 1000;
    dms = dms * 1 * count;
    return new Date(ms + dms);
}

/**
 * addHours
 *
 * @param {String|Date} date
 * @param {Number} count
 */
function subtractHours(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    if(isNaN(count)) throw new TypeError(`Invalid argument type: expected Number, got ${count}`);
    let ms = date.getTime();
    let dms = 1 * 60 * 60 * 1000;
    dms = dms * 1 * count;
    return new Date(ms - dms);
}

/**
 * addDays
 *
 * @param {String|Date} date
 * @param {Number} count
 */
function addDays(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    let ms = date.getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    dms = dms * 1 * count;
    return new Date(ms + dms);
}

/**
 * subtractDays
 *
 * @param {String|Date} date
 * @param {Number} count
 */
function subtractDays(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    let ms = date.getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    dms = dms * 1 * count;
    return new Date(ms - dms);
}

/**
 * addWeeks
 *
 * @param {String|Date} date
 * @param {Number} count
 */
function addWeeks(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    let ms = date.getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    dms = dms * 7 * count;
    return new Date(ms + dms);
}

/**
 * subtractWeeks
 *
 * @param {String|Date} date
 * @param {Number} count
 */
function subtractWeeks(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    let ms = date.getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    dms = dms * 7 * count;
    return new Date(ms - dms);
}

function addMonths(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    let ms = date.getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    dms = dms * 28 * count;
    return new Date(ms + dms);
}

function subtractMonths(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    let ms = date.getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    dms = dms * 28 * count;
    return new Date(ms - dms);
}

function addYears(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    let ms = date.getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    dms = dms * 364 * count;
    return new Date(ms + dms);
}

function subtractYears(date, count = 1){
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    let ms = date.getTime();
    let dms = 1 * 24 * 60 * 60 * 1000;
    dms = dms * 364 * count;
    return new Date(ms - dms);
}

/**
 * parseDate
 *
 * new Date("dateString") is browser-dependent and discouraged,
 * so we write a simple parse function for U.S. date
 * format (which does no error checking)
 *
 * reference: https://stackoverflow.com/questions/542938/how-do-i-get-the-number-of-days-between-two-dates-in-javascript
 *
 * @param {*} str
 */
function parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0]-1, mdy[1]);
}

/**
 * dateDiff
 *
 * Calculates the difference between two dates in milliseconds.
 *
 * @param {*} date1
 * @param {*} date2
 *
 * @return milliseconds
 */
function dateDiff(date1, date2) {
    date1 = new Date(date1);
    if(Object.prototype.toString.call(date1) !== '[object Date]')  throw new Error("Invalid date1");
    date2 = new Date(date2);
    if(Object.prototype.toString.call(date2) !== '[object Date]')  throw new Error("Invalid date2");

    let dms1    = date1.getTime(),
        dms2    = date2.getTime();

    return (dms2-dms1);
}

/**
 * dayDiff
 *
 * Calculates the difference between two dates in days.
 * Round to nearest whole number to deal with DST.
 *
 * @param {*} date1
 * @param {*} date2
 */
function dayDiff(date1, date2){
    let dms     = 1 * 24 * 60 * 60 * 1000,
        msDiff  = dateDiff(date1, date2);

    return Math.round(msDiff/dms);
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @param {Date|String} date date object or date string
 * @param {String} format long|short
 * @return {String}
 */
function getDayOfWeek(date, format='long')
{
    date = new Date(date);
    if(Object.prototype.toString.call(date) !== '[object Date]')  throw new Error("Invalid date");
    const options = { weekday: format };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

export {
    toDate, toOrdinalDate, toDateParts, toDateTime,
    toTime, toTime12,
    getWeekDates,
    dateDiff, dayDiff,
    addMinutes, subtractMinutes,
    addHours, subtractHours,
    addDays, subtractDays,
    addWeeks, subtractWeeks,
    addMonths, subtractMonths,
    addYears, subtractYears,
    getDayOfWeek
};
