import { Network } from "./utils/Network";
import { Toast } from "./utils/Toast";
import { formToJSON } from "./utils/form";
import { toDateParts } from "./utils/datetime";
import { route } from "./routes.js";
import { run } from "./utils/f";

const DATA = {
    posts: JSON.parse(dh.dataset.posts),
    pagination: JSON.parse(dh.dataset.pagination)
};

const posts = ({
    postsContainer: null,
    postsPaginationContainer: null,
    postsPerPage: null,
    paginator: null,
    postsMoreButton: null,
    startPage: null,

    init: function(options = {postsContainerId: 'latestPosts', postsPaginationContainerId: 'latestPostsPagination', postsMoreButton: document.querySelector("[data-action='more-posts]"), postsPerPage: 10, startPage: 1} )
    {
        return new Promise(function(resolve, reject){
            try
            {
                this.postsContainer = document.getElementById(options.postsContainerId);
                this.postsPaginationContainer = document.getElementById(options.postsPaginationContainerId);
                this.postsPerPage = parseInt(`${options.postsPerPage}`, 10);
                this.startPage = parseInt(`${options.startPage}`, 10);
                this.postsMoreButton = options.postsMoreButton;
                this.postsMoreButton.addEventListener("click", this.page.bind(this));

                // display preloaded posts
                for (const i of DATA.posts)
                {
                    this.postsContainer.insertAdjacentHTML("beforeend", this.parse(i));
                }
                this.paginate(DATA.pagination);

                resolve();
            }
            catch(error)
            {
                reject(new Error(error));
            }
        }.bind(this));
    },

    displayLatest: async function()
    {
        return new Promise(async function(resolve, reject){
            const container = this.postsContainer;

            if(typeof container === 'undefined')
            {
                reject(new Error(`Container is not defined.`));
            }

            const res = await this.latest(this.startPage, this.postsPerPage);

            for (const i of res.posts)
            {
                container.insertAdjacentHTML("beforeend", this.parse(i));
            }

            this.paginate(res.pagination);

            resolve();
        }.bind(this));
    },

    paginate: function(pagination)
    {
        return new Promise(function(resolve, reject){
            this.paginator = pagination;

            if(
                parseInt(`${pagination.current_page}`, 10) === parseInt(`${pagination.last_page}`, 10) ||
                parseInt(`${pagination.current_page}`, 10) > parseInt(`${pagination.last_page}`, 10)
            )
            {
                if(! this.postsPaginationContainer.classList.contains("hidden"))
                {
                    this.postsPaginationContainer.classList.add("hidden");
                }
            }
            else
            {
                this.postsPaginationContainer.classList.remove("hidden");
                this.postsMoreButton.classList.remove("loading");
                this.postsMoreButton.classList.remove("disabled");
            }

            resolve();
        }.bind(this));
    },

    page: async function(event)
    {
        return await new Promise(async function(resolve, reject){
            try
            {
                this.postsMoreButton.classList.toggle("loading");
                this.postsMoreButton.classList.toggle("disabled");

                const res = await this.latest(parseInt(`${this.paginator.current_page}`, 10) + 1, parseInt(`${this.paginator.per_page}`, 10));

                for (const i of res.posts)
                {
                    this.postsContainer.insertAdjacentHTML("beforeend", this.parse(i));
                }

                this.paginate(res.pagination);

                resolve();
            }
            catch(error)
            {
                reject(new Error(error));
            }
        }.bind(this));
    },

    parse: function(post)
    {
        const permalink = new URL(route("publicposts.show", [post.slug]));
        let figure,
            pubDate,
            pubDateParts = toDateParts(new Date(post.date_published));

        pubDate = `${pubDateParts.month} ${pubDateParts.date}, ${pubDateParts.year}`;

        if(post.featuredVideoUrl)
        {
            figure = `
            <figure>
                <div class="responsive-video">
                    <iframe src="${post.featuredVideoUrl}" frameborder="0"></iframe>
                </div>
            </figure>`;
        }
        else if(post.featuredImageUrl)
        {
            figure = `
            <figure>
                <img src="${post.featuredImageUrl}" class="featured-image">
            </figure>`;
        }

        return `
        <article class="anim fadeIn">
            <a href="${permalink}" class="global-link"></a>
            <h3>
                <a href="${permalink}" class="link primary">${post.title}</a>
            </h3>
            ${figure ?? ''}
            <p class="meta"><small>By <strong>${post.author.name}</strong> on <strong>${pubDate}</strong></small></p>
            <p class="excerpt">${post.excerpt}</p>
            <div class="category">${post.category.name}</div>
        </article>`;
    },

    latest: function(page=1, perPage=10)
    {
        return new Promise(function(resolve, reject){
            let url = new URL(route("home.posts.latest"));
            url.searchParams.set('page', page);
            url.searchParams.set('per_page', perPage);
            Network.get(url)
            .then(function(response){
                resolve(response);
            })
            .catch((error) => {
                Toast.make(error, Toast.error, Toast.DELAY_INFINITY);
                reject(new Error(error));
            });
        });
    }
});

const subscriptions = ({
    form: null,

    init: function(form)
    {
        if(typeof form === 'undefined' || form === null)
        {
            throw new Error("Subscription form is not defined!");
        }

        this.form = form;

        this.form.addEventListener("submit", this.store.bind(this));
    },

    store: function(e)
    {
        const data = formToJSON(this.form);
        Toast.make("Please wait, we are subscribing you...", Toast.info, 7);
        Network.post(new URL(route("subscribers.store")), data)
        .then(function(response){
            this.form.reset();
            Toast.make(response.message, Toast.success, 7);
        }.bind(this))
        .catch(function(error){
            Toast.make(error, Toast.error, Toast.DELAY_INFINITY);
        });
    }
});

run(async function(event){
    posts.init({
        postsContainerId: 'latestPosts',
        postsPaginationContainerId: 'latestPostsPagination',
        postsMoreButton: document.querySelector("[data-action='more-posts']"),
        postsPerPage: DATA.pagination.per_page,
        startPage: 2
    })
    .then(function(){
        posts.displayLatest();
    })
    .catch((e) => {
        if(e.name == 'AbortError') {
            return;
        }

        Toast.make(e.message, Toast.error, Toast.DELAY_INFINITY);
    });

    subscriptions.init(document.querySelector(".form.subscribe"));
});
