/**
 * Wait for specified number of seconds before calling the 
 * specified callback function
 * 
 * @param {Number} seconds 
 * @param {Function} callback 
 * @param  {...any} args 
 * @return {Number} timerId
 */
function wait(seconds, callback, ...args)
{
    seconds = parseInt(`${seconds}`);

    return setTimeout(function(){
        callback(...args);
    }, seconds * 1000)
}

/**
 * Runs the runner as soon as the DOMContentLoaded event fires
 * or immediately if it was already fired.
 *
 * @param {Function} runner
 */
function run(runner)
{
    if(document.readyState === 'loading') { document.addEventListener("DOMContentLoaded", runner); } else { runner(); }
}

/**
 * Recursively calls a callback function for each node contained
 * by a root node (including the root itself).
 * If callback is omitted, the function returns an Array instead,
 * which contains rootNode and all nodes contained within.
 * If callback is provided, and it returns Boolean false when called,
 * the current recursion level is aborted, and the function
 * resumes execution at the last parent's level.
 * This can be used to abort loops once a node has
 * been found (such as searching for a text node
 * which contains a certain string).
 *
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/Node
 *
 * @param {Node} rootNode The Node object whose
 * descendants will be recursed through.
 * @param {Node} callback An optional callback
 * function that receives a Node as its only argument.
 * If omitted, eachNode returns an Array of every node
 * contained within rootNode (including the root itself).
 * @returns
 */
function eachNode(rootNode, callback) {
	if (!callback) {
		const nodes = []
		eachNode(rootNode, function(node) {
			nodes.push(node)
		})
		return nodes
	}

	if (false === callback(rootNode)) {
		return false
    }

	if (rootNode.hasChildNodes()) {
		const nodes = rootNode.childNodes
		for (let i = 0, l = nodes.length; i < l; ++i) {
			if (false === eachNode(nodes[i], callback)) {
				return
            }
        }
	}
}

/**
 * Can be used for searching for text on a web-page.
 *
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/Node
 *
 * Usage: find text nodes that contain typos
 * -----------------------------------------
 * const typos = ["teh", "adn", "btu", "adress", "youre", "msitakes"]
 * const pattern = new RegExp("\\b(" + typos.join("|") + ")\\b", "gi")
 * const mistakes = grep(document.body, pattern)
 * console.log(mistakes)
 * ------------------------------------------
 *
 * @param {Node} parentNode
 * @param {Node} pattern
 * @returns
 */
function grep(parentNode, pattern)
{
	const matches = []
	let endScan = false

	eachNode(parentNode, function(node){
		if (endScan) {
			return false
        }

		// Ignore anything which isn't a text node
		if (node.nodeType !== Node.TEXT_NODE) {
			return
        }

		if (typeof pattern === "string") {
			if (-1 !== node.textContent.indexOf(pattern)) {
				matches.push(node)
            }
		}
		else if (pattern.test(node.textContent)) {
			if (!pattern.global) {
				endScan = true
				matches = node
			}
			else {
                matches.push(node)
            }
		}
	});

	return matches;
}

const hideLoader = () => {
	/*/ Page is fully loaded - hide the app loader /*/
	document.querySelector(`.app-loader`).classList.add("hidden");
};

export {
    run,
    wait,
    eachNode,
    grep,
	hideLoader
};
