function highZ(parent, limit)
{
    /**
     * See: https://stackoverflow.com/a/5439622/10633355
     * @param {HTMLElement} who
     * @param {*} css
     */
    const deepCss = function(who, css)
    {
        let sty, val, dv= document.defaultView || window;

        if (who.nodeType == 1)
        {
            sty = css.replace(/\-([a-z])/g, function(a, b){
                return b.toUpperCase();
            });

            val = who.style[sty];

            if (!val)
            {
                if(who.currentStyle) val= who.currentStyle[sty];

                else if (dv.getComputedStyle)
                {
                    val= dv.getComputedStyle(who,"").getPropertyValue(css);
                }
            }
        }

        return val || "";
    };

    /**
     * See: https://stackoverflow.com/a/5439622/10633355
     *
     * Stacking context:
     * https://www.w3.org/TR/CSS21/zindex.html
     * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/Stacking_without_z-index
     * https://www.freecodecamp.org/news/z-index-explained-how-to-stack-elements-using-css-7c5aa0f179b3/
     *
     * @param {HTMLElement} parent
     * @param {Number} limit
     */
    const z = function(parent, limit)
    {
        limit = limit || Infinity;
        parent = parent || document.body;
        let who, temp, max= 1, opacity, i= 0;
        let children = parent.childNodes, length = children.length;

        while(i<length)
        {
            who = children[i++];
            if (who.nodeType != 1) continue; // element nodes only
            opacity = deepCss(who,"opacity");

            if (deepCss(who,"position") !== "static")
            {
                temp = deepCss(who,"z-index");
                if (temp == "auto") { // positioned and z-index is auto, a new stacking context for opacity < 0. Further When zindex is auto ,it shall be treated as zindex = 0 within stacking context.
                    (opacity < 1)? temp=0:temp = z(who);
                } else {
                    temp = parseInt(temp, 10) || 0;
                }
            } else { // non-positioned element, a new stacking context for opacity < 1 and zindex shall be treated as if 0
                (opacity < 1)? temp=0:temp = z(who);
            }

            if (temp > max && temp <= limit) max = temp;
        }

        return max;
    };

    return parseInt(z(parent, limit), 10);
}

export { highZ };