/**
 *
 * @param {String} selector
 * @param {String} rules
 * @param {CSSStyleSheet} sheet
 *
 * @return {CSSStyleSheet} sheet used.
 */
function loadStylesheet(selector, rules, sheet=null)
{
    if(! sheet || !(sheet instanceof CSSStyleSheet))
    {
        /*/ https://davidwalsh.name/add-rules-stylesheets /*/
        sheet = (function()
        {
            let style = document.createElement("style");
            style.appendChild(document.createTextNode(""));
            document.head.appendChild(style);
            return style.sheet;
        })();
    }

    /**
     *
     * @param {CSSStyleSheet} sheet
     * @param {String} selector
     * @param {String} rules
     * @param {Number} index
     */
    let addCSSRule = function(sheet, selector, rules, index)
    {
        if("insertRule" in sheet)
        {
            sheet.insertRule(selector + "{" + rules + "}", index);
        }
        else if("addRule" in sheet)
        {
            sheet.addRule(selector, rules, index);
        }
    };

    addCSSRule(sheet, `${selector}`, `${rules}`, 0);

    return sheet;
}

/**
 * loads the specified styles in a single stylesheet.
 *
 * @param {Array<Array>} styles Array of arrays with each child array being a name-value pair
 * representing a CSS property and its value.
 *
 * Sample usage:
 \
 loadInOneStylesheet([
        [ `html`, `color: #111;` ],
        [ `.colors`, `display: grid;grid-gap: 1rem;grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));list-style: none;margin: 0;padding: 0;` ],
        [ `.color`, `border-radius: 2px;box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);padding: 0.5rem;transition: box-shadow 0.15s;` ],
        [ `.color:hover`, `box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);` ],
        [ `.color__swatch`, `background-color: var(--color);border-radius: 2px 2px 0 0;display: block;height: 11rem;` ],
        [ `.color__details`, `font-size: 1.375rem;padding: 0.5rem;text-align: right;` ],
        [ `.color input`, `border: 0;display: block;font-family: monospace;font-size: inherit;margin: 0;padding: 0.2rem;text-align: right;width: 100%;` ],
    ]);
 */
function loadInOneStylesheet(styles)
{
    let sheet = null;
    styles.forEach(function(item, index){
        sheet = loadStylesheet(item[0], item[1], sheet);
    });
}

/**
 * Returns an array of all internal and external stylesheets
 * on the current document.
 *
 * Reference: https://css-tricks.com/how-to-get-all-custom-properties-on-a-page-in-javascript/
 *
 * @return {Array<CSSStyleSheet>}
 */
function getAllStyleSheets()
{
    return [...document.styleSheets];
}

/**
 * Returns an array of all internal stylesheets
 * on the current document.
 *
 * CSSStyleSheet objects have an href property.
 * Its value is the full URL to the stylesheet,
 * like https://example.com/styles.css.
 * Internal stylesheets have an href property,
 * but the value will be null.
 *
 * @return {Array<CSSStyleSheet>}
 */
function getAllInternalStyleSheets()
{
    const isInternal = (styleSheet) => {
        if (!styleSheet.href)
        {
          return true;
        }

        return styleSheet.href.indexOf(window.location.origin) === 0;
    };

    return [...document.styleSheets].filter(isInternal);
}

/**
 * Returns an array of all external stylesheets
 * on the current document.
 *
 * @return {Array<CSSStyleSheet>}
 */
function getAllExternalStyleSheets()
{
    const isExternal = (styleSheet) => {
        if (!styleSheet.href)
        {
          return false;
        }

        return styleSheet.href.indexOf(window.location.origin) !== 0;
    };

    return [...document.styleSheets].filter(isExternal);
}

/**
 * Returns an array containing all
 * CSS rules.
 *
 * @return {Array<CSSRule>}
 */
function getAllInternalCSSRules()
{
    const isInternal = (styleSheet) => {
        if (!styleSheet.href)
        {
          return true;
        }

        return styleSheet.href.indexOf(window.location.origin) === 0;
    };

    const rulesReducer = (carry, item) => {
        carry.concat(...item.cssRules);
    };

    return [...document.styleSheets].filter(isInternal).reduce(rulesReducer, []);
}

/**
 * Returns an array containing all
 * CSS style rules.
 *
 * @return {Array<CSSStyleRule>}
 */
function getAllInternalCSSStyleRules()
{
    const isInternal = (styleSheet) => {
        if (!styleSheet.href)
        {
          return true;
        }

        return styleSheet.href.indexOf(window.location.origin) === 0;
    };

    const isStyleRule = (rule) => rule.type === 1;
    const isImportRule = (rule) => rule.type === 3;
    const isMediaRule = (rule) => rule.type === 4;
    const isFontFaceRule = (rule) => rule.type === 5;
    const isPageRule = (rule) => rule.type === 6;
    const isSupportsRule = (rule) => rule.type === 12;

    const rulesReducer = (carry, item) => {
        carry.concat([...item.cssRules].filter(isStyleRule));
    };

    return [...document.styleSheets].filter(isInternal).reduce(rulesReducer, []);
}

export {
    getAllStyleSheets,
    getAllInternalStyleSheets,
    getAllExternalStyleSheets,
    getAllInternalCSSRules,
    getAllInternalCSSStyleRules,
    loadStylesheet,
    loadInOneStylesheet
}
